import React, { useRef, useEffect } from 'react'
import { TimelineLite, Power3 } from 'gsap'
import { TweenMax } from 'gsap'

//importing css
import '../styles/Loader.css'

//importing images
import logo from '../assets/personal-logo.svg'

const Loader = () => {

    //Loader DOM Refs
    let loaderWhole = useRef(null)
    let loaderImg = useRef(null)
    let loaderHeading = useRef(null)
    let loaderDot1 = useRef(null)
    let loaderDot2 = useRef(null)
    let loaderDot3 = useRef(null)

    useEffect(() => {
        console.log(loaderWhole)
        console.log(loaderImg)
        console.log(loaderHeading)
        console.log(loaderDot1)
        console.log(loaderDot2)
        console.log(loaderDot3)

        let loaderTL = new TimelineLite({ delay: 0.8 })

        TweenMax.to(loaderWhole, 0, { css: { visibility: 'visible' } })

        loaderTL.from(loaderImg, 2, {
            scaleX: 0,
            scaleY: 0,
            transformOrigin: '"100%"r',
            ease: Power3.easeOut,
            opacity: 0,
          })
          .from(loaderHeading, 1.5, { y: 100, opacity: 0}, 1)
          .staggerFrom([loaderDot1, loaderDot2, loaderDot3], .5, { y: 20, opacity: 0, stagger: 0.2, repeat: 2}, 0.2)
          .to(loaderWhole, 2, { y: '-100vh'}, 'loader-after')
    }, [])

    return (
        <div ref={el => loaderWhole = el} className='loader-container'>
            <div className='loader-content-wrapper'>
                <img ref={el => loaderImg = el} src={logo} alt='logo'/>
                <h1 ref={el => loaderHeading = el}>PATRICK J. PARRENO</h1>
                <div  className='loading-dots-container'>
                <div ref={el => loaderDot1 = el} className='loading-dots'></div>
                <div ref={el => loaderDot2 = el} className='loading-dots'></div>
                <div ref={el => loaderDot3 = el} className='loading-dots'></div>
                </div>
            </div>
        </div>
    )
}

export default Loader
