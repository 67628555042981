import React, { useRef, useEffect } from 'react'
import { TimelineLite, Power3 } from 'gsap'


//import navbar assets
import logo from '../assets/personal-logo.svg'

//importing css
import '../styles/Navbar.css'

//importing react-scroll
import { animateScroll as scroll } from 'react-scroll'

//importing link from react router
import { Link as LinkS } from 'react-scroll'

const Navbar = () => {
  const toggleHome = () => {
    scroll.scrollToTop()
  }

  let navLogo = useRef(null)
  let navMenu = useRef(null)

  useEffect(() => {
    let tl = new TimelineLite()

    tl.from(navLogo, 1, {x: -100, ease: Power3.easeOut}, 'sametime-nav')
      .from(navMenu, 1, {x: 100, ease: Power3.easeOut}, 'sametime-nav')
  }) 

  return (
    <div className='navbar-container'>
      <img ref={el => navLogo = el} onClick={toggleHome} src={logo} alt='personal-logo' />
      <div className='nav-menu'>
        <div ref={el => navMenu = el} className='nav-links'>
          <ul>
              <li>
              <LinkS
              className='scroll-link'
              to='about-section'
              smooth={true}
              duration={1000}
              spy={true}
              exact='true'
            >
                ABOUT ME<span style={{ color: '#B2D3E4' }}>.</span>
                </LinkS>
              </li>


            <li>
            
            <LinkS
            className='scroll-link'
              to='showcase-section'
              smooth={true}
              duration={1000}
              spy={true}
              exact='true'
            >
              SHOWCASE<span style={{ color: '#B2D3E4' }}>.</span>
              </LinkS>
            </li>
            <li>
            <LinkS
            className='scroll-link'
              to='contact-section'
              smooth={true}
              duration={1000}
              spy={true}
              exact='true'
            >
              CONTACT<span style={{ color: '#B2D3E4' }}>.</span>
              </LinkS>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Navbar
