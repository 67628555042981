import React, { useEffect } from 'react'

//importing style
import '../styles/Cursor.css'

//importing gsap
import { gsap } from 'gsap'

const Cursor = () => {

    useEffect(() => {

        gsap.set('.follower', { xPercent: -50, yPercent: -50, opacity: 0})
        gsap.set('.cursor', { xPercent: -50, yPercent: -50, opacity: 0})

        const follow = document.querySelector('.follower')
        const cursor = document.querySelector('.cursor')

        window.addEventListener('mousemove', e => {
            gsap.to(cursor, 0.2, { x:e.clientX, y:e.clientY, opacity: 1})
            gsap.to(follow, 0.4, { x:e.clientX, y:e.clientY, opacity: 1})
        })
    })

    return (
        <>
            <div className='cursor'></div>
            <div className='follower'></div>
        </>
    )
}

export default Cursor
 