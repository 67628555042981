import React, { useState, useEffect } from 'react'
import './App.css'

//importing the main page
import Home from './Home'
import Loader from './components/Loader'



function App() {
  //loading state
  const [loading, setLoading] = useState(false)
  //loader timeout function
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 7500)
  }, [])

  return (
    <>
      {loading ? <Loader/> : <Home/>} 
    </>
  )
}

export default App
