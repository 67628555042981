import React, { useRef, useEffect } from 'react'

//importing gsap utilities
import { gsap } from 'gsap'
import { TimelineLite, Power3, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

//importing styles
import './styles/Hero.css'
import './styles/About.css'
import './styles/Showcase.css'
import './styles/Contact.css'

//importing components
import Navbar from './components/Navbar'
import Cursor from './components/Cursor'

//importing hero images
import spinner from './assets/spinner.svg'

//importing about images + icons
import me1 from './assets/me-2.jpg'
import me2 from './assets/me-3.jpg'
import {
  FaNodeJs,
  FaAngular,
  FaReact,
  FaHtml5,
  FaCss3Alt,
} from 'react-icons/fa'

//importing showcase images + icons + data
import portfolio from './assets/portfolio-homescreen.png'
import ecom from './assets/simple-ecom.png'
import elixr from './assets/elixr.png'
import { DiMongodb } from 'react-icons/di'
import { SiJavascript } from 'react-icons/si'

//importing contact images + icons
import { FaGithubSquare, FaLinkedin, FaDribbbleSquare } from 'react-icons/fa'

const Home = () => {
  //register scrolltrigger gsap plugin
  gsap.registerPlugin(ScrollTrigger)

  //Section DOM Refs
  let heroSection = useRef(null)
  let aboutSection = useRef(null)
  let showcaseSection = useRef(null)
  let showcaseSection2 = useRef(null)
  let showcaseSection3 = useRef(null)
  let contactSection = useRef(null)

  //hero content DOM Refs
  let heroImage = useRef(null)
  let heroImageInner = useRef(null)
  let heroSpinner = useRef(null)
  let heroContent = useRef(null)
  let heroHeading = useRef(null)
  let heroBio = useRef(null)
  let heroScroll = useRef(null)

  //about content DOM Refs
  let aboutContentLeft = useRef(null)
  let aboutTopline = useRef(null)
  let aboutBio = useRef(null)
  let aboutEducationHeading = useRef(null)
  let aboutEducationBio = useRef(null)
  let aboutSkillsHeadining = useRef(null)
  let aboutSkillsIcons = useRef(null)
  let aboutImageLeft = useRef(null)
  let aboutImageRight = useRef(null)
  let aboutImageLeftInner = useRef(null)
  let aboutImageRightInner = useRef(null)

  //showcase content DOM Refs
  let showcaseImage = useRef(null)
  let showcaseDetails = useRef(null)
  let showcaseHeading = useRef(null)
  let showcaseSubHeading = useRef(null)
  let showcaseBio = useRef(null)
  let showcaseLink = useRef(null)
  let showcaseCounter = useRef(null)
  let showcaseToolsHeading = useRef(null)
  let showcaseToolsIcons = useRef(null)

  let showcaseImage2 = useRef(null)
  let showcaseDetails2 = useRef(null)
  let showcaseHeading2 = useRef(null)
  let showcaseSubHeading2 = useRef(null)
  let showcaseBio2 = useRef(null)
  let showcaseLink2 = useRef(null)
  let showcaseCounter2 = useRef(null)
  let showcaseToolsHeading2 = useRef(null)
  let showcaseToolsIcons2 = useRef(null)

  let showcaseImage3 = useRef(null)
  let showcaseDetails3 = useRef(null)
  let showcaseHeading3 = useRef(null)
  let showcaseSubHeading3 = useRef(null)
  let showcaseBio3 = useRef(null)
  let showcaseLink3 = useRef(null)
  let showcaseCounter3 = useRef(null)
  let showcaseToolsHeading3 = useRef(null)
  let showcaseToolsIcons3 = useRef(null)

  //contact content DOM Refs
  let contactBioHeading = useRef(null)
  let contactBioParagraph = useRef(null)
  let contactSocialHeading = useRef(null)
  let contactSocialIcons = useRef(null)
  let contactContent = useRef(null)
  let contactContentFull = useRef(null)

  //hero section animations
  function heroAnimations() {
    let heroTL = new TimelineLite({ delay: 1 })

    //Right side animation
    heroTL
      .from(heroImage, 2, { opacity: 0, y: -300, ease: Power3.easeOut }, 'Hero')
      .from(heroImageInner, 2, { scale: 1.3, ease: Power3.easeOut }, 0.1)
      .from(
        heroSpinner,
        2,
        {
          scale: 0,
          transformOrigin: '"100%"r',
          ease: Power3.easeOut,
          opacity: 0,
        },
        0.2
      )
      .to(heroSpinner, 6.5, {
        rotation: '360',
        opacity: 1,
        ease: Linear.easeNone,
        repeat: -1,
      })

    //Left side animation
    heroTL
      .from(
        heroHeading,
        2,
        { y: 100, ease: Power3.easeOut, opacity: 0 },
        'Hero'
      )
      .from(heroBio, 2, { y: 100, ease: Power3.easeOut, opacity: 0 }, 0.2)
      .from(heroScroll, 2, { y: 100, ease: Power3.easeOut, opacity: 0 }, 0.4)

    //hero Section Scrub
    //defining scrolltrigger
    let heroScrub = new TimelineLite({
      scrollTrigger: {
        trigger: heroSection,
        start: '90% 60%',
        end: '+=1000px',
        scrub: 1,
        toggleActions: 'play none reverse none',
      },
    })

    //scrub actions
    heroScrub
      .to(heroImage, 1, { y: -200, ease: Power3.easeOut }, 'heroSrcub')
      .to(heroSpinner, 1, { y: -200, ease: Power3.easeOut }, 'heroSrcub')
      .to(heroContent, 1, { y: -400, ease: Power3.easeOut }, 'heroSrcub')
  }

  //about section animations
  function aboutAnimations() {
    //inital animation
    let aboutTL = new TimelineLite({
      scrollTrigger: {
        trigger: aboutSection,
        start: '30% center',
        end: '80% center',
        //markers: true,
      },
    })

    aboutTL
      .staggerFrom(
        [aboutImageRight, aboutImageLeft],
        1.5,
        { y: -400, ease: Power3, opacity: 0 },
        0.3,
        'About'
      )
      .staggerFrom(
        [aboutImageRightInner, aboutImageLeftInner],
        1.5,
        { scale: 1.2, ease: Power3.easeOut, opacity: 0 },
        0.1,
        'About'
      )
    //Left Side Animation
    aboutTL.staggerFrom(
      [
        aboutTopline,
        aboutBio,
        aboutEducationHeading,
        aboutEducationBio,
        aboutSkillsHeadining,
        aboutSkillsIcons,
      ],
      1.8,
      { y: 100, ease: Power3, delay: 0.5, opacity: 0 },
      0.2,
      'About'
    )

    //about section scrub
    //defining scrollTrigger
    let aboutScrub = new TimelineLite({
      scrollTrigger: {
        trigger: aboutSection,
        start: '80% 70%',
        end: '+=1000px',
        scrub: 1,
        toggleActions: 'play none reverse none',
        // markers: true
      },
    })
    //scrub actions
    aboutScrub
      .to(aboutContentLeft, 1, { y: -400, ease: Power3.easeOut }, 'aboutScrub')
      .to(aboutImageRight, 1, { y: -300, ease: Power3.easeOut }, 'aboutScrub')
      .to(aboutImageLeft, 1, { y: -200, ease: Power3.easeOut }, 'aboutScrub')
  }

  //showcase section animations
  function showcaseAnimations() {
    //inital animation
    let showcaseTL = new TimelineLite({
      scrollTrigger: {
        trigger: showcaseSection,
        start: '20% center',
        end: '80% center',
        //markers: true,
      },
    })

    showcaseTL
      .from(
        showcaseImage,
        1.2,
        { x: -100, ease: Power3.easeOut, opacity: 0 },
        'Showcase'
      )
      .staggerFrom(
        [
          showcaseHeading,
          showcaseSubHeading,
          showcaseBio,
          showcaseLink,
          showcaseToolsHeading,
          showcaseToolsIcons,
          showcaseCounter,
        ],
        1.2,
        { y: 20, ease: Power3.easeOut, opacity: 0, delay: 0.3 },
        0.2,
        'Showcase'
      )

    //showcase section scrub
    //defining scrollTrigger
    let showcaseScrub = new TimelineLite({
      scrollTrigger: {
        trigger: showcaseSection,
        start: '80% 70%',
        end: '+=1000px',
        //markers: true,
        scrub: 1,
        toggleActions: 'play none reverse none',
      },
    })

    showcaseScrub
      .to(showcaseImage, 1, { y: -200, ease: Power3.easeOut }, 'showcaseScrub')
      .to(
        showcaseDetails,
        1,
        { y: -100, ease: Power3.easeOut },
        'showcaseScrub'
      )
  }
  function showcaseAnimations2() {
    let showcaseTL2 = new TimelineLite({
      scrollTrigger: {
        trigger: showcaseSection2,
        start: '20% center',
        end: '80% center',
        //markers: true,
      },
    })

    showcaseTL2
      .from(
        showcaseImage2,
        1.2,
        { x: 100, ease: Power3.easeOut, opacity: 0 },
        'Showcase'
      )
      .staggerFrom(
        [
          showcaseHeading2,
          showcaseSubHeading2,
          showcaseBio2,
          showcaseLink2,
          showcaseToolsHeading2,
          showcaseToolsIcons2,
          showcaseCounter2,
        ],
        1.2,
        { y: 20, ease: Power3.easeOut, opacity: 0, delay: 0.3 },
        0.2,
        'Showcase'
      )

    //showcase section scrub
    //defining scrollTrigger
    let showcaseScrub2 = new TimelineLite({
      scrollTrigger: {
        trigger: showcaseSection2,
        start: '80% 70%',
        end: '+=1000px',
        //markers: true,
        scrub: 1,
        toggleActions: 'play none reverse none',
      },
    })

    showcaseScrub2
      .to(showcaseImage2, 1, { y: -200, ease: Power3.easeOut }, 'showcaseScrub')
      .to(
        showcaseDetails2,
        1,
        { y: -100, ease: Power3.easeOut },
        'showcaseScrub'
      )
  }
  function showcaseAnimations3() {
    let showcaseTL3 = new TimelineLite({
      scrollTrigger: {
        trigger: showcaseSection3,
        start: '20% center',
        end: '80% center',
        //markers: true,
      },
    })

    showcaseTL3
      .from(
        showcaseImage3,
        1.2,
        { x: -100, ease: Power3.easeOut, opacity: 0 },
        'Showcase'
      )
      .staggerFrom(
        [
          showcaseHeading3,
          showcaseSubHeading3,
          showcaseBio3,
          showcaseLink3,
          showcaseToolsHeading3,
          showcaseToolsIcons3,
          showcaseCounter3,
        ],
        1.2,
        { y: 20, ease: Power3.easeOut, opacity: 0, delay: 0.3 },
        0.2,
        'Showcase'
      )

    //showcase section scrub
    //defining scrollTrigger
    let showcaseScrub3 = new TimelineLite({
      scrollTrigger: {
        trigger: showcaseSection3,
        start: '80% 70%',
        end: '+=1000px',
        //markers: true,
        scrub: 1,
        toggleActions: 'play none reverse none',
      },
    })

    showcaseScrub3
      .to(showcaseImage3, 1, { y: -200, ease: Power3.easeOut }, 'showcaseScrub')
      .to(
        showcaseDetails3,
        1,
        { y: -100, ease: Power3.easeOut },
        'showcaseScrub'
      )
  }

  //contact section animations
  function contactAnimations() {
    //inital animation
    let contactTL = new TimelineLite({
      scrollTrigger: {
        trigger: contactSection,
        start: 'start start',
        end: 'bottom bottom',
      },
    })

    contactTL
      .from(
        contactBioHeading,
        1,
        { y: 44, ease: Power3.easeOut, opacity: 0 },
        0.2
      )
      .from(
        contactBioParagraph,
        1,
        { y: 44, ease: Power3.easeOut, opacity: 0 },
        0.4
      )
      .from(
        contactSocialHeading,
        1,
        { y: 44, ease: Power3.easeOut, opacity: 0 },
        0.6
      )
      .from(
        contactSocialIcons,
        1,
        { y: 44, ease: Power3.easeOut, opacity: 0 },
        0.8
      )

    //contact section scrub
    //defining scrollTrigger
    let contactScrub = new TimelineLite({
      scrollTrigger: {
        //markers: true,
        trigger: contactSection,
        start: '-10% 20%',
        end: 'bottom 90%',
        scrub: 2,
        toggleActions: 'play none reverse none',
      },
    })

    contactScrub
      .from(contactContent, 1, { y: 65, ease: Power3.easeOut }, 'contactScrub')
      .from(
        contactContentFull,
        1,
        { y: -100, ease: Power3.easeOut },
        'contactScrub'
      )
  }

  useEffect(() => {
    //hero Inital Animation
    heroAnimations()
    aboutAnimations()
    showcaseAnimations()
    showcaseAnimations2()
    showcaseAnimations3()
    contactAnimations()
  })

  return (
    <>
      <Navbar />
      <Cursor />

      {/* Hero Section */}
      <section id='hero-section'>
        <div
          ref={(el) => (heroSection = el)}
          className='hero-content-container'
        >
          <div
            ref={(el) => (heroSpinner = el)}
            className='hero-spinner-container'
          >
            <img src={spinner} alt='spinner' />
          </div>
          <div ref={(el) => (heroImage = el)} className='hero-image-container'>
            <img
              ref={(el) => (heroImageInner = el)}
              src='https://images.unsplash.com/photo-1539604880233-d282d9bac272?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80'
              alt='glacier'
            />
          </div>
          <div
            ref={(el) => (heroContent = el)}
            className='hero-content-wrapper'
          >
            <h1
              ref={(el) => (heroHeading = el)}
              className='hero-content-header'
            >
              Hello, I'm <br /> Patrick Parreno
            </h1>
            <p ref={(el) => (heroBio = el)}>
              I'm a Software Developer based in Toronto, Canada. My passion is
              creating compelling user interfaces for software applications.
              <br />
            </p>
            <div ref={(el) => (heroScroll = el)} className='scroll-row'>
              <p>Scroll Down</p>
            </div>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section id='about-section'>
        <div
          ref={(el) => (aboutSection = el)}
          className='about-content-container'
        >
          <div className='about-content-wrapper'>
            <div className='about-heading'>ABOUT ME.</div>

            <div
              ref={(el) => (aboutContentLeft = el)}
              className='about-content-left'
            >
              <div ref={(el) => (aboutTopline = el)} className='about-topline'>
                <h1>Patrick J. Parreno</h1>
                <p>SOFTWARE DEVELOPER &sdot; UX/UI DESIGNER</p>
              </div>

              <div ref={(el) => (aboutBio = el)} className='bio'>
                <p>
                  {' '}
                  Hello, I'm a Software Developer aspiring UX/UI designer.
                  Proficient in full stack development my focus is writing clean
                  and efficient code that is easy to understand at the end
                  producing a beautiful application.
                </p>
              </div>

              <div className='education'>
                <h2 ref={(el) => (aboutEducationHeading = el)}>Education</h2>
                <p
                  className='education-desc'
                  ref={(el) => (aboutEducationBio = el)}
                >
                  <b> 2016 - 2020</b> <br />
                  Advanced Diploma in Computer Programming Analyst <br />
                  George Brown College <br /> Toronto, ON
                </p>
              </div>

              <div className='skills'>
                <h2 ref={(el) => (aboutSkillsHeadining = el)}>Skills</h2>
                <div
                  ref={(el) => (aboutSkillsIcons = el)}
                  className='skills-icon-container'
                >
                  <FaNodeJs className='icon js' />
                  <FaReact className='icon react' />
                  <FaAngular className='icon angular' />
                  <FaHtml5 className='icon html' />
                  <FaCss3Alt className='icon css' />
                </div>
              </div>
            </div>

            <div className='about-content-right'>
              <div
                ref={(el) => (aboutImageLeft = el)}
                className='about-image-container-left'
              >
                <img
                  ref={(el) => (aboutImageLeftInner = el)}
                  src={me2}
                  alt='glacier'
                />
              </div>
              <div
                ref={(el) => (aboutImageRight = el)}
                className='about-image-container-right'
              >
                <img
                  ref={(el) => (aboutImageRightInner = el)}
                  src={me1}
                  alt='glacier'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Showcase Section */}

      {/* First Project For Showcase */}
      <section id='showcase-section'>
        <div
          ref={(el) => (showcaseSection = el)}
          className='showcase-content-container'
        >
          <div className='showcase-content-wrapper'>
            <div className='showcase-heading'>SHOWCASE.</div>
            <img
              ref={(el) => (showcaseImage = el)}
              src={portfolio}
              alt='filler'
            />
            <div
              ref={(el) => (showcaseDetails = el)}
              className='showcase-content-details'
            >
              <h1 ref={(el) => (showcaseHeading = el)}>Personal Portfolio</h1>
              <h2 ref={(el) => (showcaseSubHeading = el)}>
                Website &sdot; 2019
              </h2>
              <p ref={(el) => (showcaseBio = el)}>
                My personal Website that you are currently viewing. Personally
                designed and implemented in a way that displays my creative
                direction. Giving you (whoever is scrolling! ) a sense of my
                personality through my work.{' '}
              </p>
              <div ref={(el) => (showcaseLink = el)} className='showcase-link'>
                <a href='google.com'>VISIT SITE</a>
              </div>
              <h2 ref={(el) => (showcaseToolsHeading = el)}>
                Tools & Technologies Used
              </h2>
              <div
                ref={(el) => (showcaseToolsIcons = el)}
                className='showcase-content-icon-container'
              >
                <FaNodeJs className='showcase-icon js' />
                <FaReact className='showcase-icon react' />
                <FaHtml5 className='showcase-icon html' />
                <FaCss3Alt className='showcase-icon css' />
              </div>
              <div
                ref={(el) => (showcaseCounter = el)}
                className='showcase-counter-container'
              >
                01
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Second Project for Showcase */}
      <section id='showcase-section'>
        <div
          ref={(el) => (showcaseSection2 = el)}
          className='showcase-content-container-2'
        >
          <div className='showcase-content-wrapper-2'>
            <div className='showcase-heading-2'>PROJECT NO.2</div>
            <div
              ref={(el) => (showcaseDetails2 = el)}
              className='showcase-content-details-2'
            >
              <h1 ref={(el) => (showcaseHeading2 = el)}>Proshop</h1>
              <h2 ref={(el) => (showcaseSubHeading2 = el)}>
                Web Application &sdot; 2017
              </h2>
              <p ref={(el) => (showcaseBio2 = el)}>
                Ecommencre website developed with the MERN stack technologies
                and styled with React bootstrap. Fully functional application
                With administrative login privileges to manage inventory. With
                features such as working search bar with autofill, paypal API
                for payments, presistent shopping cart and the ability to leave
                user reviews.
              </p>
              <div ref={(el) => (showcaseLink2 = el)} className='showcase-link'>
                <a
                  href='https://proshopapp-pj.herokuapp.com/'
                  target='_blank'
                  rel='noreferrer'
                >
                  VISIT SITE
                </a>
              </div>
              <h2 ref={(el) => (showcaseToolsHeading2 = el)}>
                Tools & Technologies Used
              </h2>
              <div
                ref={(el) => (showcaseToolsIcons2 = el)}
                className='showcase-content-icon-container'
              >
                <DiMongodb className='showcase-icon mongo' />
                <FaNodeJs className='showcase-icon js' />
                <FaReact className='showcase-icon react' />
                <FaHtml5 className='showcase-icon html' />
                <FaCss3Alt className='showcase-icon css' />
              </div>
              <div
                ref={(el) => (showcaseCounter2 = el)}
                className='showcase-counter-container'
              >
                02
              </div>
            </div>
            <img ref={(el) => (showcaseImage2 = el)} src={ecom} alt='filler' />
          </div>
        </div>
      </section>

      {/* Third Project For Showcase */}
      <section id='showcase-section'>
        <div
          ref={(el) => (showcaseSection3 = el)}
          className='showcase-content-container'
        >
          <div className='showcase-content-wrapper'>
            <div className='showcase-heading-3'>PROJECT NO.3</div>
            <img ref={(el) => (showcaseImage3 = el)} src={elixr} alt='filler' />
            <div
              ref={(el) => (showcaseDetails3 = el)}
              className='showcase-content-details'
            >
              <h1 ref={(el) => (showcaseHeading3 = el)}>Elixr Homes</h1>
              <h2 ref={(el) => (showcaseSubHeading3 = el)}>
                Frontend Prototype &sdot; 2018
              </h2>
              <p ref={(el) => (showcaseBio3 = el)}>
                Inspired by the Realestate industry this is a Frontend
                application build with HTML, CSS and JavaScript. This was my
                first application tackling animations in a full fledge website
                with scrolling. The Animations developed in this site was made
                with pure javscript.
              </p>
              <div ref={(el) => (showcaseLink3 = el)} className='showcase-link'>
                <a
                  href='https://elixr-homes.netlify.app/'
                  target='_blank'
                  rel='noreferrer'
                >
                  VISIT SITE
                </a>
              </div>
              <h2 ref={(el) => (showcaseToolsHeading3 = el)}>
                Tools & Technologies Used
              </h2>
              <div
                ref={(el) => (showcaseToolsIcons3 = el)}
                className='showcase-content-icon-container'
              >
                <FaReact className='showcase-icon react' />
                <SiJavascript className='showcase-icon pure-js' />
                <FaHtml5 className='showcase-icon html' />
                <FaCss3Alt className='showcase-icon css' />
              </div>
              <div
                ref={(el) => (showcaseCounter3 = el)}
                className='showcase-counter-container'
              >
                03
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id='contact-section'>
        <div
          ref={(el) => (contactSection = el)}
          className='contact-content-container'
        >
          <div className='contact-content-wrapper'>
            <div className='contact-heading'>CONTACT.</div>
            <div
              ref={(el) => (contactContent = el)}
              className='contact-content-left'
            >
              <div ref={(el) => (contactContentFull = el)}>
                <h1 ref={(el) => (contactBioHeading = el)}>
                  LIKE MY WORK? REACH OUT!
                </h1>
                <p ref={(el) => (contactBioParagraph = el)}>
                  I'm always looking for opportunities to showcase my passion.{' '}
                  <br /> I can be reached at{' '}
                  <a href='mailto:patrickjparreno@gmail.com'>
                    patrickjparreno@gmail.com
                  </a>
                  . <br />I will contact back as soon as I can!
                </p>
                <div className='contact-content-socials'>
                  <h2 ref={(el) => (contactSocialHeading = el)}>Find Me At</h2>
                  <div
                    ref={(el) => (contactSocialIcons = el)}
                    className='contact-content-socials-container'
                  >
                    <a
                      href='https://github.com/PJParreno'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <FaGithubSquare className='icons github' />
                    </a>
                    <a
                      href='https://www.linkedin.com/in/patrick-parreno-6b1650178/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <FaLinkedin className='icons linkedin' />
                    </a>
                    <a
                      href='https://dribbble.com/PatrickJParreno'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <FaDribbbleSquare className='icons dribble' />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home
